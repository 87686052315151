import {onFind} from "@elements/init-modules-in-scope";
import {hasClass, removeClass, addClass, on, findIn, find, getAttribute} from "@elements/dom-utils";

let defaultOptions = {
    onlyMobile: true
};
let isOpen;


export function init (options = defaultOptions) {
    if(options.onlyMobile) {
        if (matchMedia('(min-width: 768px)').matches) {
            return;
        }
    }

    onFind('.js-overlay-toggle', function (baseElement) {
        on('click', function () {
            let targetId = baseElement.getAttribute('data-overlay-toggle-target');
            let overlayItem = find(targetId);

            if (hasClass('is-open', overlayItem)) {
                removeClass('is-open', overlayItem);
                isOpen = false;
            } else {
                addClass('is-open', overlayItem);
                isOpen = true;
            }
        }, baseElement);

        if (isOpen) {
            let targetId = baseElement.getAttribute('data-overlay-toggle-target');
            let overlayItem = find(targetId);
            addClass('is-open', overlayItem);
        }
    });
}
