import {on, find, findAllIn, findIn, empty, closest, hasClass} from '@elements/dom-utils';
import {onFind} from "@elements/init-modules-in-scope";
import asyncAppend from "@elements/async-append";
import fetch from '@elements/fetch';
import Modal from 'bootstrap/js/dist/modal';

export function init () {
    onFind('.js-ajax-modal', function (element) {
        let modalSelector = element.getAttribute('data-ajax-modal-target');
        let modal = find(modalSelector);

        if (modal) {
            let modalInit = new Modal(modal);

            on('click', function () {
                let url = element.getAttribute('data-ajax-modal-content-url');

                modalInit.show();

                asyncAppend({
                    target: findAllIn('.js-ajax-modal__content', modal),
                    loading: findAllIn('.js-ajax-modal__loading', modal)
                }, fetch(url));
            }, element);

            on('async-append', () => {
                let close = findAllIn('.js-ajax-modal__close', modal);
                close.map(button => {
                    if(button.getAttribute('type') === 'submit'){
                        let form = closest('form', button);
                        let inputs = findAllIn('input, select', form);

                        on('click', function() {
                            let hasError = false;
                            inputs.map(input => {
                                if(hasClass('is-invalid', input)){
                                    hasError = true;
                                }
                            });
                            if(!hasError){
                                modalInit.hide();
                            }

                        }, button);
                    }else{
                        on('click', function() {
                            modalInit.hide();
                        }, button);
                    }
                })
            }, document.body);

            on('hidden.bs.modal', function () {
                empty(findIn('.js-ajax-modal__content', modal));
            }, modal);
        } else {
            console.warn(`.js-ajax-modal "${modalSelector}" not found`)
        }
    });
}
