"use strict";

import {onFind} from "@elements/init-modules-in-scope";
import {findIn,addClass, findAllIn, on, removeClass} from '@elements/dom-utils';

const selectors = {
    base: '.js-tab-accordion',
    btn: '.js-tab-accordion-btn'
};

export function init () {
    onFind(selectors.base, function (baseElement) {
        let btn = findAllIn(selectors.btn, baseElement);
        let title = findIn('.js-tab-accordion__visible-title', baseElement);
        let subtitle = findIn('.js-tab-accordion__visible-subtitle', baseElement);
        let count = findIn('.js-tab-accordion__visible-count', baseElement);


        let idOfCurrentVisibleElement = findIn('.tab-pane active', baseElement);
        let button = findAllIn('.js-tab-accordion-btn', baseElement);

        button.map(function(item) {
            on('shown.bs.tab', function(event) {
                let tabTitle = findIn('.js-tab-accordion__tab-title', event.target).innerHTML;
                let tabSubtitle = findIn('.js-tab-accordion__tab-subtitle', event.target).innerHTML;
                let tabCount = findIn('.js-tab-accordion__tab-count', event.target).innerHTML;
                title.innerHTML = tabTitle;
                subtitle.innerHTML = tabSubtitle;
                count.innerHTML = tabCount;
            }, item)
        })
    });
}
