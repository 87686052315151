import {on, find} from "@elements/dom-utils";
import {onFind} from "@elements/init-modules-in-scope";
import Toast from 'bootstrap/js/dist/toast';

export function init () {
    onFind('.js-clipboard', function (baseElement) {
        on('click', (e) => {
            let value = baseElement.getAttribute('data-clipboard-value' );
            navigator.clipboard.writeText(value);

            //trigger bootstrap toast
            if(find('.js-clipboard__toast')){
                let toast = find('.js-clipboard__toast');
                console.log('toast', toast);
                const toastInstance = new Toast(toast)
                toastInstance.show()
            }

        }, baseElement);
    });
}

