"use strict";
import {closest,trigger, findAllIn, findIn, find, on, removeClass, addClass} from "@elements/dom-utils";
import {onFind} from "@elements/init-modules-in-scope";

export function init () {

    onFind('.js-multiple-select-dropdown', function (dropdown) {
        let inputs = findAllIn('input', dropdown);
        let form = dropdown.closest('form');
        let multiselectForm = find('.js-multiselect__form');
        let numberFieldAll = findIn('.js-multiselect__selected-items-all', multiselectForm);


        inputs.map(function(item) {
            on('change', function(evt) {
                let numberField = findIn('.js-multiselect__selected-items', dropdown);
                let selectedItems = findAllIn('input[type="checkbox"]:checked', dropdown).length;
                let selectedItemsAll= findAllIn('input[type="checkbox"]:checked', multiselectForm).length;

                if(selectedItems > 0){
                    removeClass('hidden', numberField);
                    numberField.innerHTML = selectedItems;
                }else{
                    addClass('hidden', numberField);
                }
                if(selectedItemsAll > 0){
                    removeClass('hidden', numberFieldAll);
                    numberFieldAll.innerHTML = selectedItemsAll;
                }else{
                    addClass('hidden', numberFieldAll);
                }
                evt.preventDefault();
                evt.stopImmediatePropagation();
            }, item)
        })

        let resetFilter = findIn('.js-ajax-form__reset-filter', dropdown);
        on('click', function () {
            console.log('reset filter')
           inputs.forEach(function (e){
               e.checked=false;
           });

            inputs.map(function(item) {
               trigger('change', item)
            });
            //trigger('submit.ajax-form', form);
        },resetFilter);


        dropdown.addEventListener('hide.bs.dropdown', function (element) {
            let dropdown = element.target;
            let form = closest('form', dropdown);

            if (dropdown.getAttribute('data-multiple-select-dropdown-submit-on-hide') === 'true'){
                trigger('submit',form);
            }
        });

        on('reset', function (){
            inputs.map(function(item) {
                trigger('change', item)
            });
        }, form)
    });

}