import {on, find, findAllIn, addClass, removeClass, hasClass} from '@elements/dom-utils';
import {onFind} from "@elements/init-modules-in-scope";

export function init () {
    onFind('.js-hover-images', function (element) {
        let links = findAllIn(".js-hover-images__link", element);
        let images = findAllIn(".js-hover-images__image", element);

        links.map(function(item) {
            let target = item.dataset.imgTarget || '';
            let targetItem = find(target);
            on('mouseenter', function (evt) {
                if (target) {
                    if (targetItem) {
                        addClass('is-active', targetItem);
                    }
                }
            }, item);
            on('mouseleave', function (evt) {
                if (target) {
                    if (targetItem) {
                        let removeActiveClass = removeClass('is-active');
                        if(!(hasClass("is-active", item))) {
                            images.map(x => removeActiveClass(x));
                        }
                    }
                }
            }, item);
        });
    });
}
