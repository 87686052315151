"use strict";
import {add} from '@elements/scroll-animations';
import {findAll} from "@elements/dom-utils";

export function initInScope($scope) {

    add(
        findAll('.js-slide-on-scroll'),
        calculateAnimationProgress,
        setAnimationProgress
    );
}

// gets element -> returns number between 0 and 1
function calculateAnimationProgress(element) {
    const start = window.innerHeight / 4 * 3;
    const end = -300;

    return 1 - Math.max(
        Math.min(
            (element.getBoundingClientRect().top - end) / (start - end),
            1
        ),
        0
    );
}

// gets element & progress (number between 0 - 1) -> sets style of element
function setAnimationProgress(element, progress) {
    let slideValue = element.getAttribute('data-slide-value') || 200;
    let slideProgress = progress * slideValue;

    element.style.transform = "translateX(-" + slideProgress + "px)";
}
